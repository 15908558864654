//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* @todo implement lazy pagination (infinite scroll) */

import Breadcrumbs from '~/components/UI/Breadcrumbs.vue'
import Paginations from '~/components/UI/Paginations.vue'
import {SET_BC} from '~/store/bc/types.bc'
import {HAS_TOKEN} from '~/store/auth/types.auth'
import SkeletonWrapper from '~/components/skeleton/SkeletonWrapper'
import SkeletonProductCard from '~/components/skeleton/SkeletonProductCard'
import RectSkeleton from '~/components/skeleton/rect'


export default {
  name: 'Index',
  components: {
    SkeletonProductCard,
    RectSkeleton,
    SkeletonWrapper,
    Breadcrumbs,
    Paginations,
    ProductItemCard: () =>
      import('~/components/modules/Cards/ProductItemCard.vue'),
  },
  data() {
    return {
      categories: [],
      loading: true,
      products: [],
      totalItems: 0,
      showCategories: false,
      showPrices: false,
      showSort: false,
      showPerPage: false,
      perPage: 15,
      page: 1,
      pages: 0,
      countItems: 0,
      attributes: [],
      orderByFilter: null,
      filters: {
        categories: [],
        price: [],
        attributes: [],
      },
      filtersArr: [],
      prices: [
        {
          slug: 1,
          min: 0,
          max: 999,
          name: '0 - 999 zl',
        },
        {
          slug: 2,
          min: 1000,
          max: 1999,
          name: '1000 - 1999 zl',
        },
        {
          slug: 3,
          min: 2000,
          max: 2999,
          name: '2000 - 2999 zl',
        },
        {
          slug: 4,
          min: 3000,
          max: 3999,
          name: '3000 - 3999 zl',
        },
        {
          slug: 5,
          min: 4000,
          max: 99999,
          name: '4000+ zl',
        },
      ],
      orderBy: [
        {
          slug: 1,
          item: 'name',
          type: 'ASC',
          name: this.$t('nameAToZ'),
        },
        {
          slug: 2,
          item: 'name',
          type: 'DESC',
          name: this.$t('nameZToA'),
        },
        {
          slug: 3,
          item: 'created_at',
          type: 'DESC',
          name: this.$t('newFirst'),
        },
        {
          slug: 4,
          item: 'popular',
          type: 'DESC',
          name: this.$t('popular'),
        },
      ],
      perPageItems: [
        15,
        30,
        60,
        90,
      ],
    }
  },
  fetch() {
    return this.fetchRoom()
  },
  computed: {
    isAuth() {
      return this.$store.getters[`auth/${HAS_TOKEN}`]
    },
    filtersCounter() {
      let a = 0
      for (const key in this.filters) {
        if (this.filters[key] && this.filters[key].length) {
          a = a + this.filters[key].length
        }
      }
      return a
    },
  },
  mounted() {
    /* setTimeout(() => {
      console.log('this.categories', this.categories)
      console.log('this.products', this.products)
    }, 500) */
    this.$request.$get('/attributes').then(res => {
      for (const key in res.data) {
        this.attributes.push({
          name: key,
          attributes: res.data[key].map(item => {
            return {
              name: key,
              slug: item,
            }
          }),
        })
        // this.$set(this.filters, key, [])
      }
    })
    if (this.isAuth) {
      this.orderBy = [...this.orderBy,{
        slug: 5,
        item: 'price',
        type: 'ASC',
        name: this.$t('priceLowToHigh'),
      },
      {
        slug: 6,
        item: 'price',
        type: 'DESC',
        name: this.$t('priceHighToLow'),
      },
      ]
    }
    this.loading = false
  },
  methods: {
    clearAll() {
      for (const key in this.filters) {
        this.filters[key] = []
      }
      this.filtersArr = []
      this.$refs['sidebar-right'].hide()
      this.getProducts()
    },
    setSort(item) {
      if (this.orderByFilter && this.orderByFilter.slug === item.slug) {
        this.orderByFilter = null
      } else {
        this.orderByFilter = item
      }
      this.getProducts()
    },
    setPerPage(item) {
      this.closePerPage()
      if(this.perPage !== item) {
        this.perPage = item
        this.getProducts()
      }
    },
    setFilter(item, type, a) {
      if (!this.filters[type].some(i => i.slug === item.slug)) {
        this.filters[type].push(item)
        const t = item
        item.type = type
        this.filtersArr.push(t)
      } else {
        this.filters[type] = this.filters[type].filter(i => i.slug !== item.slug)
        this.filtersArr = this.filtersArr.filter(i => i.slug !== item.slug)
      }
      this.getProducts()
    },
    getProducts() {
      const params = {
        per_page: this.perPage,
        page: this.page,
        categories: this.filters.categories.map(item => item.slug),
        /*  */
      }
      for (const key in this.filters) {
        if (this.filters[key] && this.filters[key].length) {
          params[key] = this.filters[key].map(item => item.slug)
        }
      }
      params.price = this.filters.price.map(item => {
        return {
          value: item.min + '-' + item.max,
        }
      })
      params.attributes = this.filters.attributes.map(item => {
        return {
          [item.name]: item.slug,
        }
      })

      if (this.orderByFilter) {
        params.orderBy = {}
        params.orderBy[this.orderByFilter.item] = this.orderByFilter.type
      }

      return this.$request.$get('/catalog/products', params).then(res => {
        this.totalItems = res.meta.pagination.total
        this.pages = res.meta.pagination.total_pages
        this.countItems = res.meta.pagination.count

        this.products = res.data.map(item => {
          return {
            ...item,
            variations: item.variations.map((variation) => {
              return {
                ...variation,
                file: variation.files[0] ? variation.files[0].preview_url : '',
              }
            }),
          }
        })
      })
    },
    closeCategories() {
      this.showCategories = false
    },
    closePrices() {
      this.showPrices = false
    },
    closeSort() {
      this.showSort = false
    },
    closePerPage() {
      this.showPerPage = false
    },
    fetchRoom() {
      return this.$request.$get('/catalog').then(res => {
        this.categories = res.data
        this.$store.commit(`bc/${SET_BC}`, [
          {name: 'Catalog', slug: '/product/category'},
        ])
        return this.$request.$get('/catalog/products', {per_page: this.perPage}).then(res => {
          this.totalItems = res.meta.pagination.total
          this.pages = res.meta.pagination.total_pages
          this.countItems = res.meta.pagination.count

          this.products = res.data.map(item => {
            return {
              ...item,
              variations: item.variations.map((variation) => {
                return {
                  ...variation,
                  file: variation.files[0] ? variation.files[0].preview_url : '',
                }
              }),
            }
          })
        })
      })
    },
    onClick(value) {
      const {id, data} = value
      const keys = ['labels', 'status', 'name', 'price_type', 'slug']
      const slide = this.items.find((item) => item.id === id)
      for (const key of keys) {
        slide[key] = data[key]
      }
      const variations = Object.keys(data.variations).filter(
        (key) => key !== 'primary',
      )
      slide.variations = data.variations(
        data.variations.primary ? data.variations.primary : variations[0],
      ).map((variation) => ({
        ...variation,
        file: variation.files[0]?.preview_url ? variation.files[0].preview_url : '',
      }))
    },
    selectOffset(value) {
      this.perPage = value
    },
    onSelectPage(value) {
      this.page = value
      this.getProducts()
    },
  },
}
